/* ImageMapster
   Version: 1.2.8 (12/30/2012)

Copyright 2011-2012 James Treworgy

http://www.outsharked.com/imagemapster
https://github.com/jamietre/ImageMapster

A jQuery plugin to enhance image maps.

*/
(function (e) {
  e(function () {
    function i() {}
    function s(t) {
      var n = new i();
      return (
        (n.then = function (e) {
          var n;
          try {
            return e && (n = e(t)), c(n === r ? t : n);
          } catch (i) {
            return o(i);
          }
        }),
        e(n)
      );
    }
    function o(t) {
      var n = new i();
      return (
        (n.then = function (e, n) {
          var i;
          try {
            return n ? ((i = n(t)), c(i === r ? t : i)) : o(t);
          } catch (s) {
            return o(s);
          }
        }),
        e(n)
      );
    }
    function u(e) {
      return l(e, function (e) {
        return o(e);
      });
    }
    function a() {
      function p(e, t, n) {
        return l(e, t, n);
      }
      function d(e) {
        h(s(e));
      }
      function v(e) {
        h(o(e));
      }
      function m(e) {
        c(e);
      }
      var t, n, u, f, l, c, h;
      return (
        (u = []),
        (f = []),
        (l = function (t, n, r) {
          var i = a();
          return (
            u.push(function (e) {
              e.then(t, n).then(i.resolve, i.reject, i.progress);
            }),
            r && f.push(r),
            i.promise
          );
        }),
        (c = function (e) {
          var t,
            n = 0;
          while ((t = f[n++])) t(e);
        }),
        (h = function (e) {
          var t,
            n = 0;
          (l = e.then),
            (h = c =
              function () {
                throw new Error('already completed');
              }),
            (f = r);
          while ((t = u[n++])) t(e);
          u = [];
        }),
        (t = {}),
        (n = new i()),
        (n.then = t.then = p),
        (t.promise = e(n)),
        (t.resolver = e({
          resolve: (t.resolve = d),
          reject: (t.reject = v),
          progress: (t.progress = m),
        })),
        t
      );
    }
    function f(e) {
      return e && typeof e.then == 'function';
    }
    function l(e, t, n, r) {
      var i = c(e);
      return i.then(t, n, r);
    }
    function c(e) {
      var t, n;
      return (
        e instanceof i
          ? (t = e)
          : ((n = a()),
            f(e)
              ? (e.then(n.resolve, n.reject, n.progress), (t = n.promise))
              : (n.resolve(e), (t = n.promise))),
        t
      );
    }
    function h(e, t, n, r, i) {
      return (
        E(2, arguments),
        l(e, function (e) {
          function m(e) {
            c(e);
          }
          function g(e) {
            h(e);
          }
          function y(e) {
            p(e);
          }
          function b() {
            c = h = p = S;
          }
          var s, o, u, f, c, h, p, d, v;
          (d = e.length >>> 0),
            (s = Math.max(0, Math.min(t, d))),
            (o = []),
            (f = a()),
            (u = l(f, n, r, i));
          if (!s) f.resolve(o);
          else {
            (c = function (e) {
              o.push(e), --s || (b(), f.resolve(o));
            }),
              (h = function (e) {
                b(), f.reject(e);
              }),
              (p = f.progress);
            for (v = 0; v < d; ++v) v in e && l(e[v], m, g, y);
          }
          return u;
        })
      );
    }
    function p(e, t, n, r) {
      return (
        E(1, arguments),
        l(e, function (e) {
          return b(e, d, []);
        }).then(t, n, r)
      );
    }
    function d(e, t, n) {
      return (e[n] = t), e;
    }
    function v(e, t, n, r) {
      function i(e) {
        return t ? t(e[0]) : e[0];
      }
      return h(e, 1, i, n, r);
    }
    function m(e, t) {
      return l(e, function (e) {
        return g(e, t);
      });
    }
    function g(e, t) {
      var n, r, i;
      (r = e.length >>> 0), (n = new Array(r));
      for (i = 0; i < r; i++) i in e && (n[i] = l(e[i], t));
      return b(n, d, n);
    }
    function y(e, t, i) {
      var s = n.call(arguments, 1);
      return l(e, function (e) {
        return b.apply(r, [e].concat(s));
      });
    }
    function b(e, n, r) {
      var i, s;
      return (
        (i = e.length),
        (s = [
          function (e, t, r) {
            return l(e, function (e) {
              return l(t, function (t) {
                return n(e, t, r, i);
              });
            });
          },
        ]),
        arguments.length > 2 && s.push(r),
        t.apply(e, s)
      );
    }
    function w(e, t, n) {
      var r = arguments.length > 2;
      return l(
        e,
        function (e) {
          return r && (e = n), t.resolve(e), e;
        },
        function (e) {
          return t.reject(e), o(e);
        },
        t.progress
      );
    }
    function E(e, t) {
      var n,
        r = t.length;
      while (r > e) {
        n = t[--r];
        if (n != null && typeof n != 'function')
          throw new Error('callback is not a function');
      }
    }
    function S() {}
    var e, t, n, r;
    return (
      (l.defer = a),
      (l.reject = u),
      (l.isPromise = f),
      (l.all = p),
      (l.some = h),
      (l.any = v),
      (l.map = m),
      (l.reduce = y),
      (l.chain = w),
      (e =
        Object.freeze ||
        function (e) {
          return e;
        }),
      (i.prototype = e({
        always: function (e, t) {
          return this.then(e, e, t);
        },
        otherwise: function (e) {
          return this.then(r, e);
        },
      })),
      (n = [].slice),
      (t =
        [].reduce ||
        function (e) {
          var t, n, r, i, s;
          (s = 0), (t = Object(this)), (i = t.length >>> 0), (n = arguments);
          if (n.length <= 1)
            for (;;) {
              if (s in t) {
                r = t[s++];
                break;
              }
              if (++s >= i) throw new TypeError();
            }
          else r = n[1];
          for (; s < i; ++s) s in t && (r = e(r, t[s], s, t));
          return r;
        }),
      l
    );
  });
})(
  typeof define == 'function'
    ? define
    : function (e) {
        typeof module != 'undefined'
          ? (module.exports = e())
          : (jQuery.mapster_when = e());
      }
),
  (function ($) {
    ($.fn.mapster = function (e) {
      var t = $.mapster.impl;
      if ($.isFunction(t[e]))
        return t[e].apply(this, Array.prototype.slice.call(arguments, 1));
      if (typeof e == 'object' || !e) return t.bind.apply(this, arguments);
      $.error('Method ' + e + ' does not exist on jQuery.mapster');
    }),
      ($.mapster = {
        version: '1.2.8',
        render_defaults: {
          isSelectable: !0,
          isDeselectable: !0,
          fade: !1,
          fadeDuration: 150,
          fill: !0,
          fillColor: '000000',
          fillColorMask: 'FFFFFF',
          fillOpacity: 0.7,
          highlight: !0,
          stroke: !1,
          strokeColor: 'ff0000',
          strokeOpacity: 1,
          strokeWidth: 1,
          includeKeys: '',
          altImage: null,
          altImageId: null,
          altImages: {},
        },
        defaults: {
          clickNavigate: !1,
          wrapClass: null,
          wrapCss: null,
          onGetList: null,
          sortList: !1,
          listenToList: !1,
          mapKey: '',
          mapValue: '',
          singleSelect: !1,
          listKey: 'value',
          listSelectedAttribute: 'selected',
          listSelectedClass: null,
          onClick: null,
          onMouseover: null,
          onMouseout: null,
          mouseoutDelay: 0,
          onStateChange: null,
          boundList: null,
          onConfigured: null,
          configTimeout: 3e4,
          noHrefIsMask: !0,
          scaleMap: !0,
          safeLoad: !1,
          areas: [],
        },
        shared_defaults: {
          render_highlight: { fade: !0 },
          render_select: { fade: !1 },
          staticState: null,
          selected: null,
        },
        area_defaults: { includeKeys: '', isMask: !1 },
        canvas_style: {
          position: 'absolute',
          left: 0,
          top: 0,
          padding: 0,
          border: 0,
        },
        hasCanvas: null,
        isTouch: null,
        windowLoaded: !1,
        map_cache: [],
        hooks: {},
        addHook: function (e, t) {
          this.hooks[e] = (this.hooks[e] || []).push(t);
        },
        callHooks: function (e, t) {
          $.each(this.hooks[e] || [], function (e, n) {
            n.apply(t);
          });
        },
        utils: {
          when: $.mapster_when,
          defer: $.mapster_when.defer,
          subclass: function (e, t) {
            var n = function () {
              var n = this,
                r = Array.prototype.slice.call(arguments, 0);
              (n.base = e.prototype),
                (n.base.init = function () {
                  e.prototype.constructor.apply(n, r);
                }),
                t.apply(n, r);
            };
            return (n.prototype = new e()), (n.prototype.constructor = n), n;
          },
          asArray: function (e) {
            return e.constructor === Array ? e : this.split(e);
          },
          split: function (e, t) {
            var n,
              r,
              i = e.split(',');
            for (n = 0; n < i.length; n++)
              (r = $.trim(i[n])),
                r === '' ? i.splice(n, 1) : (i[n] = t ? t(r) : r);
            return i;
          },
          updateProps: function (e, t) {
            var n,
              r = e || {},
              i = $.isEmptyObject(r) ? t : e;
            return (
              (n = []),
              $.each(i, function (e) {
                n.push(e);
              }),
              $.each(Array.prototype.slice.call(arguments, 1), function (e, t) {
                $.each(t || {}, function (e) {
                  if (!n || $.inArray(e, n) >= 0) {
                    var i = t[e];
                    $.isPlainObject(i)
                      ? (r[e] = $.extend(r[e] || {}, i))
                      : i && i.constructor === Array
                      ? (r[e] = i.slice(0))
                      : typeof i != 'undefined' && (r[e] = t[e]);
                  }
                });
              }),
              r
            );
          },
          isElement: function (e) {
            return typeof HTMLElement == 'object'
              ? e instanceof HTMLElement
              : e &&
                  typeof e == 'object' &&
                  e.nodeType === 1 &&
                  typeof e.nodeName == 'string';
          },
          indexOfProp: function (e, t, n) {
            var r = e.constructor === Array ? -1 : null;
            return (
              $.each(e, function (e, i) {
                if (i && (t ? i[t] : i) === n) return (r = e), !1;
              }),
              r
            );
          },
          boolOrDefault: function (e, t) {
            return this.isBool(e) ? e : t || !1;
          },
          isBool: function (e) {
            return typeof e == 'boolean';
          },
          isUndef: function (e) {
            return typeof e == 'undefined';
          },
          ifFunction: function (e, t, n) {
            $.isFunction(e) && e.call(t, n);
          },
          size: function (e, t) {
            var n = $.mapster.utils;
            return {
              width: t ? e.width || e.naturalWidth : n.imgWidth(e, !0),
              height: t ? e.height || e.naturalHeight : n.imgHeight(e, !0),
              complete: function () {
                return !!this.height && !!this.width;
              },
            };
          },
          setOpacity: function (e, t) {
            e.style.opacity = t;
          },
          fader: (function () {
            var e = {},
              t = 0,
              n = function (r, i, s, o) {
                var u,
                  a = o / 15,
                  f,
                  l = $.mapster.utils;
                if (typeof r == 'number') {
                  f = e[r];
                  if (!f) return;
                } else
                  (u = l.indexOfProp(e, null, r)),
                    u && delete e[u],
                    (e[++t] = f = r),
                    (r = t);
                (s = s || 1),
                  (i = i + s / a > s - 0.01 ? s : i + s / a),
                  l.setOpacity(f, i),
                  i < s &&
                    setTimeout(function () {
                      n(r, i, s, o);
                    }, 15);
              };
            return n;
          })(),
        },
        getBoundList: function (e, t) {
          if (!e.boundList) return null;
          var n,
            r,
            i = $(),
            s = $.mapster.utils.split(t);
          return (
            e.boundList.each(function (t, o) {
              for (n = 0; n < s.length; n++)
                (r = s[n]),
                  $(o).is('[' + e.listKey + '="' + r + '"]') && (i = i.add(o));
            }),
            i
          );
        },
        setBoundListProperties: function (e, t, n) {
          t.each(function (t, r) {
            e.listSelectedClass &&
              (n
                ? $(r).addClass(e.listSelectedClass)
                : $(r).removeClass(e.listSelectedClass)),
              e.listSelectedAttribute && $(r).attr(e.listSelectedAttribute, n);
          });
        },
        getMapDataIndex: function (e) {
          var t, n;
          switch (e.tagName && e.tagName.toLowerCase()) {
            case 'area':
              (n = $(e).parent().attr('name')),
                (t = $("img[usemap='#" + n + "']")[0]);
              break;
            case 'img':
              t = e;
          }
          return t ? this.utils.indexOfProp(this.map_cache, 'image', t) : -1;
        },
        getMapData: function (e) {
          var t = this.getMapDataIndex(e.length ? e[0] : e);
          if (t >= 0) return t >= 0 ? this.map_cache[t] : null;
        },
        queueCommand: function (e, t, n, r) {
          return e
            ? !e.complete || e.currentAction
              ? (e.commands.push({ that: t, command: n, args: r }), !0)
              : !1
            : !1;
        },
        unload: function () {
          this.impl.unload(),
            (this.utils = null),
            (this.impl = null),
            ($.fn.mapster = null),
            ($.mapster = null),
            $('*').unbind();
        },
      });
    var m = $.mapster,
      u = m.utils,
      ap = Array.prototype;
    $.each(['width', 'height'], function (e, t) {
      var n = t.substr(0, 1).toUpperCase() + t.substr(1);
      u['img' + n] = function (e, r) {
        return (
          (r ? $(e)[t]() : 0) ||
          e[t] ||
          e['natural' + n] ||
          e['client' + n] ||
          e['offset' + n]
        );
      };
    }),
      (m.Method = function (e, t, n, r) {
        var i = this;
        (i.name = r.name),
          (i.output = e),
          (i.input = e),
          (i.first = r.first || !1),
          (i.args = r.args ? ap.slice.call(r.args, 0) : []),
          (i.key = r.key),
          (i.func_map = t),
          (i.func_area = n),
          (i.name = r.name),
          (i.allowAsync = r.allowAsync || !1);
      }),
      (m.Method.prototype.go = function () {
        var e,
          t,
          n,
          r,
          i,
          s = this.input,
          o = [],
          u = this;
        r = s.length;
        for (e = 0; e < r; e++) {
          t = $.mapster.getMapData(s[e]);
          if (t) {
            if (!u.allowAsync && m.queueCommand(t, u.input, u.name, u.args)) {
              this.first && (i = '');
              continue;
            }
            (n = t.getData(s[e].nodeName === 'AREA' ? s[e] : this.key)),
              n
                ? $.inArray(n, o) < 0 && o.push(n)
                : (i = this.func_map.apply(t, u.args));
            if (this.first || typeof i != 'undefined') break;
          }
        }
        return (
          $(o).each(function (e, t) {
            i = u.func_area.apply(t, u.args);
          }),
          typeof i != 'undefined' ? i : this.output
        );
      }),
      ($.mapster.impl = (function () {
        function merge_areas(e, t) {
          var n,
            r,
            i = e.options.areas;
          t &&
            $.each(t, function (t, s) {
              if (!s || !s.key) return;
              (r = u.indexOfProp(i, 'key', s.key)),
                r >= 0 ? $.extend(i[r], s) : i.push(s),
                (n = e.getDataForKey(s.key)),
                n && $.extend(n.options, s);
            });
        }
        function merge_options(e, t) {
          var n = u.updateProps({}, t);
          delete n.areas,
            u.updateProps(e.options, n),
            merge_areas(e, t.areas),
            u.updateProps(e.area_options, e.options);
        }
        var me = {},
          removeMap,
          addMap;
        return (
          (addMap = function (e) {
            return m.map_cache.push(e) - 1;
          }),
          (removeMap = function (e) {
            m.map_cache.splice(e.index, 1);
            for (var t = m.map_cache.length - 1; t >= this.index; t--)
              m.map_cache[t].index--;
          }),
          (me.get = function (e) {
            var t = m.getMapData(this);
            if (!t || !t.complete)
              throw "Can't access data until binding complete.";
            return new m.Method(
              this,
              function () {
                return this.getSelected();
              },
              function () {
                return this.isSelected();
              },
              {
                name: 'get',
                args: arguments,
                key: e,
                first: !0,
                allowAsync: !0,
                defaultReturn: '',
              }
            ).go();
          }),
          (me.data = function (e) {
            return new m.Method(
              this,
              null,
              function () {
                return this;
              },
              { name: 'data', args: arguments, key: e }
            ).go();
          }),
          (me.highlight = function (e) {
            return new m.Method(
              this,
              function () {
                if (e !== !1) {
                  var t = this.highlightId;
                  return t >= 0 ? this.data[t].key : null;
                }
                this.ensureNoHighlight();
              },
              function () {
                this.highlight();
              },
              { name: 'highlight', args: arguments, key: e, first: !0 }
            ).go();
          }),
          (me.keys = function (e, t) {
            function i(e) {
              var r,
                i = [];
              t
                ? ((r = e.areas()),
                  $.each(r, function (e, t) {
                    i = i.concat(t.keys);
                  }))
                : i.push(e.key),
                $.each(i, function (e, t) {
                  $.inArray(t, n) < 0 && n.push(t);
                });
            }
            var n = [],
              r = m.getMapData(this);
            if (!r || !r.complete)
              throw "Can't access data until binding complete.";
            return !r || !r.complete
              ? ''
              : (typeof e == 'string'
                  ? t
                    ? i(r.getDataForKey(e))
                    : (n = [r.getKeysForGroup(e)])
                  : ((t = e),
                    this.each(function (e, t) {
                      t.nodeName === 'AREA' && i(r.getDataForArea(t));
                    })),
                n.join(','));
          }),
          (me.select = function () {
            me.set.call(this, !0);
          }),
          (me.deselect = function () {
            me.set.call(this, !1);
          }),
          (me.set = function (e, t, n) {
            function f(t) {
              if (t)
                switch (e) {
                  case !0:
                    t.select(s);
                    break;
                  case !1:
                    t.deselect(!0);
                    break;
                  default:
                    t.toggle(s);
                }
            }
            function l(e) {
              e &&
                $.inArray(e, a) < 0 &&
                (a.push(e), (o += (o === '' ? '' : ',') + e.key));
            }
            function c(t) {
              $.each(a, function (e, t) {
                f(t);
              }),
                e || t.removeSelectionFinish(),
                t.options.boundList &&
                  m.setBoundListProperties(
                    t.options,
                    m.getBoundList(t.options, o),
                    e
                  );
            }
            var r,
              i,
              s = n,
              o,
              a;
            return (
              this.filter('img,area').each(function (n, f) {
                var h;
                (i = m.getMapData(f)),
                  i !== r && (r && c(r), (a = []), (o = '')),
                  i &&
                    ((h = ''),
                    f.nodeName.toUpperCase() === 'IMG'
                      ? m.queueCommand(i, $(f), 'set', [e, t, s]) ||
                        (t instanceof Array
                          ? t.length && (h = t.join(','))
                          : (h = t),
                        h &&
                          $.each(u.split(h), function (e, t) {
                            l(i.getDataForKey(t.toString())), (r = i);
                          }))
                      : ((s = t),
                        m.queueCommand(i, $(f), 'set', [e, s]) ||
                          (l(i.getDataForArea(f)), (r = i))));
              }),
              i && c(i),
              this
            );
          }),
          (me.unbind = function (e) {
            return new m.Method(
              this,
              function () {
                this.clearEvents(), this.clearMapData(e), removeMap(this);
              },
              null,
              { name: 'unbind', args: arguments }
            ).go();
          }),
          (me.rebind = function (e) {
            return new m.Method(
              this,
              function () {
                var t = this;
                (t.complete = !1),
                  t.configureOptions(e),
                  t.bindImages().then(function () {
                    t.buildDataset(!0), (t.complete = !0);
                  });
              },
              null,
              { name: 'rebind', args: arguments }
            ).go();
          }),
          (me.get_options = function (e, t) {
            var n = u.isBool(e) ? e : t;
            return new m.Method(
              this,
              function () {
                var e = $.extend({}, this.options);
                return (
                  n &&
                    ((e.render_select = u.updateProps(
                      {},
                      m.render_defaults,
                      e,
                      e.render_select
                    )),
                    (e.render_highlight = u.updateProps(
                      {},
                      m.render_defaults,
                      e,
                      e.render_highlight
                    ))),
                  e
                );
              },
              function () {
                return n ? this.effectiveOptions() : this.options;
              },
              {
                name: 'get_options',
                args: arguments,
                first: !0,
                allowAsync: !0,
                key: e,
              }
            ).go();
          }),
          (me.set_options = function (e) {
            return new m.Method(
              this,
              function () {
                merge_options(this, e);
              },
              null,
              { name: 'set_options', args: arguments }
            ).go();
          }),
          (me.unload = function () {
            var e;
            for (e = m.map_cache.length - 1; e >= 0; e--)
              m.map_cache[e] && me.unbind.call($(m.map_cache[e].image));
            me.graphics = null;
          }),
          (me.snapshot = function () {
            return new m.Method(
              this,
              function () {
                $.each(this.data, function (e, t) {
                  t.selected = !1;
                }),
                  (this.base_canvas = this.graphics.createVisibleCanvas(this)),
                  $(this.image).before(this.base_canvas);
              },
              null,
              { name: 'snapshot' }
            ).go();
          }),
          (me.state = function () {
            var e,
              t = null;
            return (
              $(this).each(function (n, r) {
                if (r.nodeName === 'IMG')
                  return (e = m.getMapData(r)), e && (t = e.state()), !1;
              }),
              t
            );
          }),
          (me.bind = function (e) {
            return this.each(function (t, n) {
              var r, i, s, o;
              (r = $(n)), (o = m.getMapData(n));
              if (o) {
                me.unbind.apply(r);
                if (!o.complete) return r.bind(), !0;
                o = null;
              }
              (s = this.getAttribute('usemap')),
                (i = s && $('map[name="' + s.substr(1) + '"]'));
              if (!(r.is('img') && s && i.size() > 0)) return !0;
              r.css('border', 0),
                o ||
                  ((o = new m.MapData(this, e)),
                  (o.index = addMap(o)),
                  (o.map = i),
                  o.bindImages().then(function () {
                    o.initialize();
                  }));
            });
          }),
          (me.init = function (e) {
            var t, n;
            (m.hasCanvas =
              document.namespaces && document.namespaces.g_vml_
                ? !1
                : $('<canvas></canvas>')[0].getContext
                ? !0
                : !1),
              (m.isTouch = 'ontouchstart' in document.documentElement);
            if (!m.hasCanvas && !document.namespaces) {
              $.fn.mapster = function () {
                return this;
              };
              return;
            }
            $.extend(m.defaults, m.render_defaults, m.shared_defaults),
              $.extend(m.area_defaults, m.render_defaults, m.shared_defaults),
              u.isBool(e) && (m.hasCanvas = e),
              $.browser.msie &&
                !m.hasCanvas &&
                !document.namespaces.v &&
                (document.namespaces.add('v', 'urn:schemas-microsoft-com:vml'),
                (t = document.createStyleSheet()),
                (n = [
                  'shape',
                  'rect',
                  'oval',
                  'circ',
                  'fill',
                  'stroke',
                  'imagedata',
                  'group',
                  'textbox',
                ]),
                $.each(n, function (e, n) {
                  t.addRule(
                    'v\\:' + n,
                    'behavior: url(#default#VML); antialias:true'
                  );
                }));
          }),
          (me.test = function (obj) {
            return eval(obj);
          }),
          me
        );
      })()),
      $.mapster.impl.init();
  })(jQuery),
  (function (e) {
    function i(t, n, r) {
      var i = t,
        s = i.map_data,
        o = r.isMask;
      e.each(n.areas(), function (e, t) {
        (r.isMask = o || (t.nohref && s.options.noHrefIsMask)),
          i.addShape(t, r);
      }),
        (r.isMask = o);
    }
    var t,
      n = e.mapster,
      r = n.utils;
    (n.Graphics = function (e) {
      var t = this;
      (t.active = !1),
        (t.canvas = null),
        (t.width = 0),
        (t.height = 0),
        (t.shapes = []),
        (t.masks = []),
        (t.map_data = e);
    }),
      (t = n.Graphics.prototype =
        {
          constructor: n.Graphics,
          begin: function (t, n) {
            var r = e(t);
            (this.elementName = n),
              (this.canvas = t),
              (this.width = r.width()),
              (this.height = r.height()),
              (this.shapes = []),
              (this.masks = []),
              (this.active = !0);
          },
          addShape: function (e, t) {
            var n = t.isMask ? this.masks : this.shapes;
            n.push({ mapArea: e, options: t });
          },
          createVisibleCanvas: function (t) {
            return e(this.createCanvasFor(t))
              .addClass('mapster_el')
              .css(n.canvas_style)[0];
          },
          addShapeGroup: function (t, s, o) {
            var a = this,
              f,
              l,
              c,
              h = this.map_data,
              p = t.effectiveRenderOptions(s);
            o && e.extend(p, o),
              s === 'select'
                ? ((l = 'static_' + t.areaId.toString()), (c = h.base_canvas))
                : (c = h.overlay_canvas),
              a.begin(c, l),
              p.includeKeys &&
                ((f = r.split(p.includeKeys)),
                e.each(f, function (e, t) {
                  var n = h.getDataForKey(t.toString());
                  i(a, n, n.effectiveRenderOptions(s));
                })),
              i(a, t, p),
              a.render(),
              p.fade &&
                r.fader(
                  n.hasCanvas ? c : e(c).find('._fill').not('.mapster_mask'),
                  0,
                  n.hasCanvas ? 1 : p.fillOpacity,
                  p.fadeDuration
                );
          },
        }),
      n.hasCanvas
        ? ((t.hex_to_decimal = function (e) {
            return Math.max(0, Math.min(parseInt(e, 16), 255));
          }),
          (t.css3color = function (e, t) {
            return (
              'rgba(' +
              this.hex_to_decimal(e.substr(0, 2)) +
              ',' +
              this.hex_to_decimal(e.substr(2, 2)) +
              ',' +
              this.hex_to_decimal(e.substr(4, 2)) +
              ',' +
              t +
              ')'
            );
          }),
          (t.renderShape = function (e, t, n) {
            var r,
              i = t.coords(null, n);
            switch (t.shape) {
              case 'rect':
                e.rect(i[0], i[1], i[2] - i[0], i[3] - i[1]);
                break;
              case 'poly':
                e.moveTo(i[0], i[1]);
                for (r = 2; r < t.length; r += 2) e.lineTo(i[r], i[r + 1]);
                e.lineTo(i[0], i[1]);
                break;
              case 'circ':
              case 'circle':
                e.arc(i[0], i[1], i[2], 0, Math.PI * 2, !1);
            }
          }),
          (t.addAltImage = function (e, t, n, r) {
            e.beginPath(),
              this.renderShape(e, n),
              e.closePath(),
              e.clip(),
              (e.globalAlpha = r.altImageOpacity || r.fillOpacity),
              e.drawImage(
                t,
                0,
                0,
                n.owner.scaleInfo.width,
                n.owner.scaleInfo.height
              );
          }),
          (t.render = function () {
            var t,
              n,
              r = this,
              i = r.map_data,
              s = r.masks.length,
              o = r.createCanvasFor(i),
              u = o.getContext('2d'),
              a = r.canvas.getContext('2d');
            return (
              s &&
                ((t = r.createCanvasFor(i)),
                (n = t.getContext('2d')),
                n.clearRect(0, 0, t.width, t.height),
                e.each(r.masks, function (e, t) {
                  n.save(),
                    n.beginPath(),
                    r.renderShape(n, t.mapArea),
                    n.closePath(),
                    n.clip(),
                    (n.lineWidth = 0),
                    (n.fillStyle = '#000'),
                    n.fill(),
                    n.restore();
                })),
              e.each(r.shapes, function (e, t) {
                u.save(),
                  t.options.fill &&
                    (t.options.altImageId
                      ? r.addAltImage(
                          u,
                          i.images[t.options.altImageId],
                          t.mapArea,
                          t.options
                        )
                      : (u.beginPath(),
                        r.renderShape(u, t.mapArea),
                        u.closePath(),
                        (u.fillStyle = r.css3color(
                          t.options.fillColor,
                          t.options.fillOpacity
                        )),
                        u.fill())),
                  u.restore();
              }),
              e.each(r.shapes.concat(r.masks), function (e, t) {
                var n = t.options.strokeWidth === 1 ? 0.5 : 0;
                t.options.stroke &&
                  (u.save(),
                  (u.strokeStyle = r.css3color(
                    t.options.strokeColor,
                    t.options.strokeOpacity
                  )),
                  (u.lineWidth = t.options.strokeWidth),
                  u.beginPath(),
                  r.renderShape(u, t.mapArea, n),
                  u.closePath(),
                  u.stroke(),
                  u.restore());
              }),
              s
                ? ((n.globalCompositeOperation = 'source-out'),
                  n.drawImage(o, 0, 0),
                  a.drawImage(t, 0, 0))
                : a.drawImage(o, 0, 0),
              (r.active = !1),
              r.canvas
            );
          }),
          (t.createCanvasFor = function (t) {
            return e(
              '<canvas width="' +
                t.scaleInfo.width +
                '" height="' +
                t.scaleInfo.height +
                '"></canvas>'
            )[0];
          }),
          (t.clearHighlight = function () {
            var e = this.map_data.overlay_canvas;
            e.getContext('2d').clearRect(0, 0, e.width, e.height);
          }),
          (t.removeSelections = function () {}),
          (t.refreshSelections = function () {
            var t,
              n = this.map_data;
            (t = n.base_canvas),
              (n.base_canvas = this.createVisibleCanvas(n)),
              e(n.base_canvas).hide(),
              e(t).before(n.base_canvas),
              n.redrawSelections(),
              e(n.base_canvas).show(),
              e(t).remove();
          }))
        : ((r.setOpacity = function (t, n) {
            e(t).each(function (t, r) {
              typeof r.opacity != 'undefined'
                ? (r.opacity = n)
                : e(r).css('opacity', n);
            });
          }),
          (t.renderShape = function (t, n, r) {
            var i = this,
              s,
              o,
              u,
              a,
              f,
              l,
              c,
              h = t.coords();
            (f = i.elementName ? 'name="' + i.elementName + '" ' : ''),
              (l = r ? 'class="' + r + '" ' : ''),
              (a =
                '<v:fill color="#' +
                n.fillColor +
                '" class="_fill" opacity="' +
                (n.fill ? n.fillOpacity : 0) +
                '" /><v:stroke class="_fill" opacity="' +
                n.strokeOpacity +
                '"/>'),
              (o = n.stroke
                ? ' strokeweight=' +
                  n.strokeWidth +
                  ' stroked="t" strokecolor="#' +
                  n.strokeColor +
                  '"'
                : ' stroked="f"'),
              (s = n.fill ? ' filled="t"' : ' filled="f"');
            switch (t.shape) {
              case 'rect':
                c =
                  '<v:rect ' +
                  l +
                  f +
                  s +
                  o +
                  ' style="zoom:1;margin:0;padding:0;display:block;position:absolute;left:' +
                  h[0] +
                  'px;top:' +
                  h[1] +
                  'px;width:' +
                  (h[2] - h[0]) +
                  'px;height:' +
                  (h[3] - h[1]) +
                  'px;">' +
                  a +
                  '</v:rect>';
                break;
              case 'poly':
                c =
                  '<v:shape ' +
                  l +
                  f +
                  s +
                  o +
                  ' coordorigin="0,0" coordsize="' +
                  i.width +
                  ',' +
                  i.height +
                  '" path="m ' +
                  h[0] +
                  ',' +
                  h[1] +
                  ' l ' +
                  h.slice(2).join(',') +
                  ' x e" style="zoom:1;margin:0;padding:0;display:block;position:absolute;top:0px;left:0px;width:' +
                  i.width +
                  'px;height:' +
                  i.height +
                  'px;">' +
                  a +
                  '</v:shape>';
                break;
              case 'circ':
              case 'circle':
                c =
                  '<v:oval ' +
                  l +
                  f +
                  s +
                  o +
                  ' style="zoom:1;margin:0;padding:0;display:block;position:absolute;left:' +
                  (h[0] - h[2]) +
                  'px;top:' +
                  (h[1] - h[2]) +
                  'px;width:' +
                  h[2] * 2 +
                  'px;height:' +
                  h[2] * 2 +
                  'px;">' +
                  a +
                  '</v:oval>';
            }
            return (u = e(c)), e(i.canvas).append(u), u;
          }),
          (t.render = function () {
            var t,
              n = this;
            return (
              e.each(this.shapes, function (e, t) {
                n.renderShape(t.mapArea, t.options);
              }),
              this.masks.length &&
                e.each(this.masks, function (e, i) {
                  (t = r.updateProps({}, i.options, {
                    fillOpacity: 1,
                    fillColor: i.options.fillColorMask,
                  })),
                    n.renderShape(i.mapArea, t, 'mapster_mask');
                }),
              (this.active = !1),
              this.canvas
            );
          }),
          (t.createCanvasFor = function (t) {
            var n = t.scaleInfo.width,
              r = t.scaleInfo.height;
            return e(
              '<var width="' +
                n +
                '" height="' +
                r +
                '" style="zoom:1;overflow:hidden;display:block;width:' +
                n +
                'px;height:' +
                r +
                'px;"></var>'
            )[0];
          }),
          (t.clearHighlight = function () {
            e(this.map_data.overlay_canvas).children().remove();
          }),
          (t.removeSelections = function (t) {
            t >= 0
              ? e(this.map_data.base_canvas)
                  .find('[name="static_' + t.toString() + '"]')
                  .remove()
              : e(this.map_data.base_canvas).children().remove();
          }),
          (t.refreshSelections = function () {
            return null;
          }));
  })(jQuery),
  (function (e) {
    var t = e.mapster,
      n = t.utils,
      r = [];
    (t.MapImages = function (e) {
      (this.owner = e), this.clear();
    }),
      (t.MapImages.prototype = {
        constructor: t.MapImages,
        slice: function () {
          return r.slice.apply(this, arguments);
        },
        splice: function () {
          r.slice.apply(this.status, arguments);
          var e = r.slice.apply(this, arguments);
          return e;
        },
        complete: function () {
          return e.inArray(!1, this.status) < 0;
        },
        _add: function (e) {
          var t = r.push.call(this, e) - 1;
          return (this.status[t] = !1), t;
        },
        indexOf: function (t) {
          return e.inArray(t, this);
        },
        clear: function () {
          var t = this;
          t.ids &&
            t.ids.length > 0 &&
            e.each(t.ids, function (e, n) {
              delete t[n];
            }),
            (t.ids = []),
            (t.length = 0),
            (t.status = []),
            t.splice(0);
        },
        add: function (t, n) {
          var r,
            i,
            s = this;
          if (!t) return;
          if (typeof t == 'string') {
            (i = t), (t = s[i]);
            if (typeof t == 'object') return s.indexOf(t);
            (t = e('<img />').addClass('mapster_el').hide()),
              (r = s._add(t[0])),
              t
                .bind('load', function (e) {
                  s.imageLoaded.call(s, e);
                })
                .bind('error', function (e) {
                  s.imageLoadError.call(s, e);
                }),
              t.attr('src', i);
          } else r = s._add(e(t)[0]);
          if (n) {
            if (this[n])
              throw (
                n + ' is already used or is not available as an altImage alias.'
              );
            s.ids.push(n), (s[n] = s[r]);
          }
          return r;
        },
        bind: function (e) {
          var t = this,
            r,
            i = t.owner.options.configTimeout / 200,
            s = function () {
              var e;
              e = t.length;
              while (e-- > 0) if (!t.isLoaded(e)) break;
              t.complete()
                ? t.resolve()
                : i-- > 0
                ? (t.imgTimeout = window.setTimeout(function () {
                    s.call(t, !0);
                  }, 50))
                : t.imageLoadError.call(t);
            };
          return (r = t.deferred = n.defer()), s(), r;
        },
        resolve: function () {
          var e = this,
            t = e.deferred;
          t && ((e.deferred = null), t.resolve());
        },
        imageLoaded: function (t) {
          var n = this,
            r = n.indexOf(t.target);
          r >= 0 &&
            ((n.status[r] = !0), e.inArray(!1, n.status) < 0 && n.resolve());
        },
        imageLoadError: function (e) {
          clearTimeout(this.imgTimeout), (this.triesLeft = 0);
          var t = e
            ? 'The image ' + e.target.src + ' failed to load.'
            : 'The images never seemed to finish loading. You may just need to increase the configTimeout if images could take a long time to load.';
          throw t;
        },
        isLoaded: function (e) {
          var t,
            r = this,
            i = r.status;
          return i[e]
            ? !0
            : ((t = r[e]),
              typeof t.complete != 'undefined'
                ? (i[e] = t.complete)
                : (i[e] = !!n.imgWidth(t)),
              i[e]);
        },
      });
  })(jQuery),
  (function (e) {
    function r(t) {
      e.extend(t, {
        complete: !1,
        map: null,
        base_canvas: null,
        overlay_canvas: null,
        commands: [],
        data: [],
        mapAreas: [],
        _xref: {},
        highlightId: -1,
        currentAreaId: -1,
        _tooltip_events: [],
        scaleInfo: null,
        index: -1,
        activeAreaEvent: null,
      });
    }
    function i(e) {
      return [e, e.render_highlight, e.render_select];
    }
    function s(r) {
      var s = r.options,
        o = r.images;
      e.mapster.hasCanvas &&
        (e.each(s.altImages || {}, function (e, t) {
          o.add(t, e);
        }),
        e.each([s].concat(s.areas), function (t, n) {
          e.each(i(n), function (e, t) {
            t && t.altImage && (t.altImageId = o.add(t.altImage));
          });
        })),
        (r.area_options = n.updateProps({}, t.area_defaults, s));
    }
    function o(e, t, r, i) {
      function s(t) {
        e.currentAreaId !== t && e.highlightId >= 0 && i.resolve();
      }
      (i = i || n.when.defer()),
        e.activeAreaEvent &&
          (window.clearTimeout(e.activeAreaEvent), (e.activeAreaEvent = 0));
      if (t < 0) return;
      return (
        r.owner.currentAction || t
          ? (e.activeAreaEvent = window.setTimeout(
              (function () {
                return function () {
                  o(e, 0, r, i);
                };
              })(r),
              t || 100
            ))
          : s(r.areaId),
        i
      );
    }
    function u(t) {
      e.mapster.hasCanvas || this.blur(), t.preventDefault();
    }
    function a(t, n) {
      var r = t.getAllDataForArea(this),
        i = r.length ? r[0] : null;
      if (!i || i.isNotRendered() || i.owner.currentAction) return;
      if (t.currentAreaId === i.areaId) return;
      t.highlightId !== i.areaId &&
        (t.clearEffects(),
        i.highlight(),
        t.options.showToolTip &&
          e.each(r, function (e, t) {
            t.effectiveOptions().toolTip && t.showToolTip();
          })),
        (t.currentAreaId = i.areaId),
        e.isFunction(t.options.onMouseover) &&
          t.options.onMouseover.call(this, {
            e: n,
            options: i.effectiveOptions(),
            key: i.key,
            selected: i.isSelected(),
          });
    }
    function f(t, n) {
      var r,
        i = t.getDataForArea(this),
        s = t.options;
      if (t.currentAreaId < 0 || !i) return;
      r = t.getDataForArea(n.relatedTarget);
      if (r === i) return;
      (t.currentAreaId = -1),
        (i.area = null),
        o(t, s.mouseoutDelay, i).then(t.clearEffects),
        e.isFunction(s.onMouseout) &&
          s.onMouseout.call(this, {
            e: n,
            options: s,
            key: i.key,
            selected: i.isSelected(),
          });
    }
    function l(t) {
      var n = t.options;
      t.ensureNoHighlight(),
        n.toolTipClose &&
          e.inArray('area-mouseout', n.toolTipClose) >= 0 &&
          t.activeToolTip &&
          t.clearToolTip();
    }
    function c(r, i) {
      function v(u) {
        var p, g;
        (l = u.isSelectable() && (u.isDeselectable() || !u.isSelected())),
          l ? (f = !u.isSelected()) : (f = u.isSelected()),
          (a = t.getBoundList(d, u.key));
        if (e.isFunction(d.onClick)) {
          c = d.onClick.call(h, {
            e: i,
            listTarget: a,
            key: u.key,
            selected: f,
          });
          if (n.isBool(c)) {
            if (!c) return !1;
            g = e(u.area).attr('href');
            if (g !== '#') return (window.location.href = g), !1;
          }
        }
        l && (s = u.toggle()),
          d.boundList &&
            d.boundList.length > 0 &&
            t.setBoundListProperties(d, a, u.isSelected()),
          (p = u.effectiveOptions()),
          p.includeKeys &&
            ((o = n.split(p.includeKeys)),
            e.each(o, function (e, t) {
              var n = r.getDataForKey(t.toString());
              n.options.isMask || v(n);
            }));
      }
      var s,
        o,
        a,
        f,
        l,
        c,
        h = this,
        p = r.getDataForArea(this),
        d = r.options;
      u.call(this, i);
      if (d.clickNavigate && p.href) {
        window.location.href = p.href;
        return;
      }
      p && !p.owner.currentAction && ((d = r.options), v(p));
    }
    var t = e.mapster,
      n = t.utils;
    (t.MapData = function (e, n) {
      var i = this;
      (i.image = e),
        (i.images = new t.MapImages(i)),
        (i.graphics = new t.Graphics(i)),
        (i.imgCssText = e.style.cssText || null),
        r(i),
        i.configureOptions(n),
        (i.mouseover = function (e) {
          a.call(this, i, e);
        }),
        (i.mouseout = function (e) {
          f.call(this, i, e);
        }),
        (i.click = function (e) {
          c.call(this, i, e);
        }),
        (i.clearEffects = function (e) {
          l.call(this, i, e);
        });
    }),
      (t.MapData.prototype = {
        constructor: t.MapData,
        configureOptions: function (e) {
          this.options = n.updateProps({}, t.defaults, e);
        },
        bindImages: function () {
          var e = this,
            t = e.images;
          return (
            t.length > 2
              ? t.splice(2)
              : t.length === 0 && (t.add(e.image), t.add(e.image.src)),
            s(e),
            e.images.bind()
          );
        },
        isActive: function () {
          return !this.complete || this.currentAction;
        },
        state: function () {
          return {
            complete: this.complete,
            resizing: this.currentAction === 'resizing',
            zoomed: this.zoomed,
            zoomedArea: this.zoomedArea,
            scaleInfo: this.scaleInfo,
          };
        },
        wrapId: function () {
          return 'mapster_wrap_' + this.index;
        },
        _idFromKey: function (e) {
          return typeof e == 'string' && this._xref.hasOwnProperty(e)
            ? this._xref[e]
            : -1;
        },
        getSelected: function () {
          var t = '';
          return (
            e.each(this.data, function (e, n) {
              n.isSelected() && (t += (t ? ',' : '') + this.key);
            }),
            t
          );
        },
        getAllDataForArea: function (t, r) {
          var i,
            s,
            o,
            u = this,
            a = e(t).filter('area').attr(u.options.mapKey);
          if (a) {
            (o = []), (a = n.split(a));
            for (i = 0; i < (r || a.length); i++)
              (s = u.data[u._idFromKey(a[i])]),
                (s.area = t.length ? t[0] : t),
                o.push(s);
          }
          return o;
        },
        getDataForArea: function (e) {
          var t = this.getAllDataForArea(e, 1);
          return t ? t[0] || null : null;
        },
        getDataForKey: function (e) {
          return this.data[this._idFromKey(e)];
        },
        getKeysForGroup: function (e) {
          var t = this.getDataForKey(e);
          return t
            ? t.isPrimary
              ? t.key
              : this.getPrimaryKeysForMapAreas(t.areas()).join(',')
            : '';
        },
        getPrimaryKeysForMapAreas: function (t) {
          var n = [];
          return (
            e.each(t, function (t, r) {
              e.inArray(r.keys[0], n) < 0 && n.push(r.keys[0]);
            }),
            n
          );
        },
        getData: function (e) {
          return typeof e == 'string'
            ? this.getDataForKey(e)
            : (e && e.mapster) || n.isElement(e)
            ? this.getDataForArea(e)
            : null;
        },
        ensureNoHighlight: function () {
          var e;
          this.highlightId >= 0 &&
            (this.graphics.clearHighlight(),
            (e = this.data[this.highlightId]),
            e.changeState('highlight', !1),
            this.setHighlightId(-1));
        },
        setHighlightId: function (e) {
          this.highlightId = e;
        },
        clearSelections: function () {
          e.each(this.data, function (e, t) {
            t.selected && t.deselect(!0);
          }),
            this.removeSelectionFinish();
        },
        setAreaOptions: function (e) {
          var t, r, i;
          e = e || [];
          for (t = e.length - 1; t >= 0; t--)
            (r = e[t]),
              r &&
                ((i = this.getDataForKey(r.key)),
                i &&
                  (n.updateProps(i.options, r),
                  n.isBool(r.selected) && (i.selected = r.selected)));
        },
        drawSelections: function (e) {
          var t,
            r = n.asArray(e);
          for (t = r.length - 1; t >= 0; t--) this.data[r[t]].drawSelection();
        },
        redrawSelections: function () {
          e.each(this.data, function (e, t) {
            t.isSelectedOrStatic() && t.drawSelection();
          });
        },
        initialize: function () {
          var r,
            i,
            s,
            o,
            u,
            a,
            f,
            l,
            c,
            h,
            p,
            d,
            v = this,
            g = v.options;
          if (v.complete) return;
          (c = e(v.image)),
            (u = c.parent().attr('id')),
            u && u.length >= 12 && u.substring(0, 12) === 'mapster_wrap'
              ? ((o = c.parent()), o.attr('id', v.wrapId()))
              : ((o = e('<div id="' + v.wrapId() + '"></div>')),
                g.wrapClass &&
                  (g.wrapClass === !0
                    ? o.addClass(c[0].className)
                    : o.addClass(g.wrapClass))),
            (v.wrapper = o),
            (v.scaleInfo = d =
              n.scaleMap(v.images[0], v.images[1], g.scaleMap)),
            (v.base_canvas = i = v.graphics.createVisibleCanvas(v)),
            (v.overlay_canvas = s = v.graphics.createVisibleCanvas(v)),
            (r = e(v.images[1])
              .addClass('mapster_el ' + v.images[0].className)
              .attr({ id: null, usemap: null })),
            (l = n.size(v.images[0])),
            l.complete && r.css({ width: l.width, height: l.height }),
            v.buildDataset(),
            (a = {
              display: 'block',
              position: 'relative',
              padding: 0,
              width: d.width,
              height: d.height,
            }),
            g.wrapCss && e.extend(a, g.wrapCss),
            c.parent()[0] !== v.wrapper[0] && c.before(v.wrapper),
            o.css(a),
            e(v.images.slice(2)).hide();
          for (f = 1; f < v.images.length; f++) o.append(v.images[f]);
          o.append(i).append(s).append(c.css(t.canvas_style)),
            n.setOpacity(v.images[0], 0),
            e(v.images[1]).show(),
            n.setOpacity(v.images[1], 1),
            g.isSelectable &&
              g.onGetList &&
              ((p = v.data.slice(0)),
              g.sortList &&
                (g.sortList === 'desc'
                  ? (h = function (e, t) {
                      return e === t ? 0 : e > t ? -1 : 1;
                    })
                  : (h = function (e, t) {
                      return e === t ? 0 : e < t ? -1 : 1;
                    }),
                p.sort(function (e, t) {
                  return (e = e.value), (t = t.value), h(e, t);
                })),
              (v.options.boundList = g.onGetList.call(v.image, p))),
            (v.complete = !0),
            v.processCommandQueue(),
            g.onConfigured &&
              typeof g.onConfigured == 'function' &&
              g.onConfigured.call(c, !0);
        },
        buildDataset: function (n) {
          function E(e, n) {
            var r = new t.AreaData(y, e, n);
            return (r.areaId = y._xref[e] = y.data.push(r) - 1), r.areaId;
          }
          var r,
            i,
            s,
            o,
            u,
            a,
            f,
            l,
            c,
            h,
            p,
            d,
            v,
            g,
            y = this,
            b = y.options,
            w;
          (y._xref = {}),
            (y.data = []),
            n || (y.mapAreas = []),
            (w = !b.mapKey),
            w && (b.mapKey = 'data-mapster-key'),
            (r =
              e.browser.msie && e.browser.version <= 7
                ? 'area'
                : w
                ? 'area[coords]'
                : 'area[' + b.mapKey + ']'),
            (i = e(y.map).find(r).unbind('.mapster'));
          for (p = 0; p < i.length; p++) {
            (o = 0), (a = i[p]), (u = e(a));
            if (!a.coords) continue;
            w
              ? ((f = String(p)), u.attr('data-mapster-key', f))
              : (f = a.getAttribute(b.mapKey)),
              n
                ? ((l = y.mapAreas[u.data('mapster') - 1]), l.configure(f))
                : ((l = new t.MapArea(y, a, f)), y.mapAreas.push(l)),
              (h = l.keys);
            for (s = h.length - 1; s >= 0; s--)
              (c = h[s]),
                b.mapValue && (d = u.attr(b.mapValue)),
                w
                  ? ((o = E(y.data.length, d)),
                    (v = y.data[o]),
                    (v.key = c = o.toString()))
                  : ((o = y._xref[c]),
                    o >= 0
                      ? ((v = y.data[o]),
                        d && !y.data[o].value && (v.value = d))
                      : ((o = E(c, d)),
                        (v = y.data[o]),
                        (v.isPrimary = s === 0))),
                l.areaDataXref.push(o),
                v.areasXref.push(p);
            (g = u.attr('href')),
              g && g !== '#' && !v.href && (v.href = g),
              l.nohref ||
                (u.bind('click.mapster', y.click),
                t.isTouch ||
                  u
                    .bind('mouseover.mapster', y.mouseover)
                    .bind('mouseout.mapster', y.mouseout)
                    .bind('mousedown.mapster', y.mousedown)),
              u.data('mapster', p + 1);
          }
          y.setAreaOptions(b.areas), y.redrawSelections();
        },
        processCommandQueue: function () {
          var e,
            n = this;
          while (!n.currentAction && n.commands.length)
            (e = n.commands[0]),
              n.commands.splice(0, 1),
              t.impl[e.command].apply(e.that, e.args);
        },
        clearEvents: function () {
          e(this.map).find('area').unbind('.mapster'),
            e(this.images).unbind('.mapster');
        },
        _clearCanvases: function (t) {
          t || e(this.base_canvas).remove(), e(this.overlay_canvas).remove();
        },
        clearMapData: function (t) {
          var r = this;
          this._clearCanvases(t),
            e.each(this.data, function (e, t) {
              t.reset();
            }),
            (this.data = null),
            t ||
              ((this.image.style.cssText = this.imgCssText),
              e(this.wrapper).before(this.image).remove()),
            r.images.clear(),
            (this.image = null),
            n.ifFunction(this.clearTooltip, this);
        },
        removeSelectionFinish: function () {
          var e = this.graphics;
          e.refreshSelections(), e.clearHighlight();
        },
      });
  })(jQuery),
  (function (e) {
    function r(t) {
      var n = this,
        r = n.owner;
      r.options.singleSelect && r.clearSelections(),
        n.isSelected() ||
          (t &&
            (n.optsCache = e.extend(n.effectiveRenderOptions('select'), t, {
              altImageId: r.images.add(t.altImage),
            })),
          n.drawSelection(),
          (n.selected = !0),
          n.changeState('select', !0)),
        r.options.singleSelect && r.graphics.refreshSelections();
    }
    function i(e) {
      var t = this;
      (t.selected = !1),
        t.changeState('select', !1),
        (t.optsCache = null),
        t.owner.graphics.removeSelections(t.areaId),
        e || t.owner.removeSelectionFinish();
    }
    function s(e) {
      var t = this;
      return t.isSelected() ? t.deselect() : t.select(e), t.isSelected();
    }
    var t = e.mapster,
      n = t.utils;
    (t.AreaData = function (t, n, r) {
      e.extend(this, {
        owner: t,
        key: n || '',
        isPrimary: !0,
        areaId: -1,
        href: '',
        value: r || '',
        options: {},
        selected: null,
        areasXref: [],
        area: null,
        optsCache: null,
      });
    }),
      (t.AreaData.prototype = {
        constuctor: t.AreaData,
        select: r,
        deselect: i,
        toggle: s,
        areas: function () {
          var e,
            t = [];
          for (e = 0; e < this.areasXref.length; e++)
            t.push(this.owner.mapAreas[this.areasXref[e]]);
          return t;
        },
        coords: function (t) {
          var n = [];
          return (
            e.each(this.areas(), function (e, r) {
              n = n.concat(r.coords(t));
            }),
            n
          );
        },
        reset: function () {
          e.each(this.areas(), function (e, t) {
            t.reset();
          }),
            (this.areasXref = []),
            (this.options = null);
        },
        isSelectedOrStatic: function () {
          var e = this.effectiveOptions();
          return n.isBool(e.staticState) ? e.staticState : this.isSelected();
        },
        isSelected: function () {
          return n.isBool(this.selected)
            ? this.selected
            : n.isBool(this.owner.area_options.selected)
            ? this.owner.area_options.selected
            : !1;
        },
        isSelectable: function () {
          return n.isBool(this.effectiveOptions().staticState)
            ? !1
            : n.isBool(this.owner.options.staticState)
            ? !1
            : n.boolOrDefault(this.effectiveOptions().isSelectable, !0);
        },
        isDeselectable: function () {
          return n.isBool(this.effectiveOptions().staticState)
            ? !1
            : n.isBool(this.owner.options.staticState)
            ? !1
            : n.boolOrDefault(this.effectiveOptions().isDeselectable, !0);
        },
        isNotRendered: function () {
          var t = e(this.area);
          return (
            t.attr('nohref') ||
            !t.attr('href') ||
            this.effectiveOptions().isMask
          );
        },
        effectiveOptions: function (e) {
          var t = n.updateProps(
            {},
            this.owner.area_options,
            this.options,
            e || {},
            { id: this.areaId }
          );
          return (t.selected = this.isSelected()), t;
        },
        effectiveRenderOptions: function (t, r) {
          var i,
            s = this.optsCache;
          if (!s || t === 'highlight')
            (i = this.effectiveOptions(r)),
              (s = n.updateProps({}, i, i['render_' + t])),
              t !== 'highlight' && (this.optsCache = s);
          return e.extend({}, s);
        },
        changeState: function (t, n) {
          e.isFunction(this.owner.options.onStateChange) &&
            this.owner.options.onStateChange.call(this.owner.image, {
              key: this.key,
              state: t,
              selected: n,
            });
        },
        highlight: function (e) {
          var t = this.owner;
          this.effectiveOptions().highlight &&
            t.graphics.addShapeGroup(this, 'highlight', e),
            t.setHighlightId(this.areaId),
            this.changeState('highlight', !0);
        },
        drawSelection: function () {
          this.owner.graphics.addShapeGroup(this, 'select');
        },
      }),
      (t.MapArea = function (t, r, i) {
        if (!t) return;
        var s = this;
        (s.owner = t),
          (s.area = r),
          (s.areaDataXref = []),
          (s.originalCoords = []),
          e.each(n.split(r.coords), function (e, t) {
            s.originalCoords.push(parseFloat(t));
          }),
          (s.length = s.originalCoords.length),
          (s.shape = r.shape.toLowerCase()),
          (s.nohref = r.nohref || !r.href),
          s.configure(i);
      }),
      (t.MapArea.prototype = {
        constructor: t.MapArea,
        configure: function (e) {
          this.keys = n.split(e);
        },
        reset: function () {
          this.area = null;
        },
        coords: function (t) {
          return e.map(this.originalCoords, function (e) {
            return t ? e : e + t;
          });
        },
      });
  })(jQuery),
  (function (e) {
    var t = e.mapster.utils;
    t.areaCorners = function (n, r, i, s, o) {
      var a,
        f,
        l,
        c,
        h,
        p,
        d,
        v,
        m,
        g,
        y,
        b,
        w,
        E,
        S = 0,
        x = 0,
        T,
        N,
        C,
        k,
        L,
        A,
        O = [];
      (n = n.length ? n : [n]),
        (i = i ? e(i) : e(document.body)),
        (a = i.offset()),
        (T = a.left),
        (N = a.top),
        r && ((a = e(r).offset()), (S = a.left), (x = a.top));
      for (E = 0; E < n.length; E++) {
        A = n[E];
        if (A.nodeName === 'AREA') {
          C = t.split(A.coords, parseInt);
          switch (A.shape) {
            case 'circle':
              (y = C[0]), (b = C[1]), (k = C[2]), (O = []);
              for (E = 0; E < 360; E += 20)
                (L = (E * Math.PI) / 180),
                  O.push(y + k * Math.cos(L), b + k * Math.sin(L));
              break;
            case 'rect':
              O.push(C[0], C[1], C[2], C[1], C[2], C[3], C[0], C[3]);
              break;
            default:
              O = O.concat(C);
          }
          for (E = 0; E < O.length; E += 2)
            (O[E] = parseInt(O[E], 10) + S),
              (O[E + 1] = parseInt(O[E + 1], 10) + x);
        } else
          (A = e(A)),
            (a = A.position()),
            O.push(
              a.left,
              a.top,
              a.left + A.width(),
              a.top,
              a.left + A.width(),
              a.top + A.height(),
              a.left,
              a.top + A.height()
            );
      }
      (l = c = d = m = 999999), (h = p = v = g = -1);
      for (E = O.length - 2; E >= 0; E -= 2)
        (y = O[E]),
          (b = O[E + 1]),
          y < l && ((l = y), (g = b)),
          y > h && ((h = y), (m = b)),
          b < c && ((c = b), (v = y)),
          b > p && ((p = b), (d = y));
      return (
        s &&
          o &&
          ((f = !1),
          e.each(
            [
              [v - s, c - o],
              [d, c - o],
              [l - s, g - o],
              [l - s, m],
              [h, g - o],
              [h, m],
              [v - s, p],
              [d, p],
            ],
            function (e, t) {
              if (!f && t[0] > T && t[1] > N) return (w = t), (f = !0), !1;
            }
          ),
          f || (w = [h, p])),
        w
      );
    };
  })(jQuery),
  (function (e) {
    var t = e.mapster,
      n = t.utils,
      r = t.MapArea.prototype;
    (t.utils.getScaleInfo = function (e, t) {
      var n;
      return (
        t
          ? ((n = e.width / t.width || e.height / t.height),
            n > 0.98 && n < 1.02 && (n = 1))
          : ((n = 1), (t = e)),
        {
          scale: n !== 1,
          scalePct: n,
          realWidth: t.width,
          realHeight: t.height,
          width: e.width,
          height: e.height,
          ratio: e.width / e.height,
        }
      );
    }),
      (t.utils.scaleMap = function (e, t, r) {
        var i = n.size(e),
          s = n.size(t, !0);
        if (!s.complete())
          throw 'Another script, such as an extension, appears to be interfering with image loading. Please let us know about this.';
        return i.complete() || (i = s), this.getScaleInfo(i, r ? s : null);
      }),
      (t.MapData.prototype.resize = function (t, r, i, s) {
        function d(t, n, r) {
          e.mapster.hasCanvas
            ? ((t.width = n), (t.height = r))
            : (e(t).width(n), e(t).height(r));
        }
        function v() {
          (p.currentAction = ''),
            e.isFunction(s) && s(),
            p.processCommandQueue();
        }
        function m() {
          d(p.overlay_canvas, t, r);
          if (c >= 0) {
            var e = p.data[c];
            (e.tempOptions = { fade: !1 }),
              p.getDataForKey(e.key).highlight(),
              (e.tempOptions = null);
          }
          d(p.base_canvas, t, r), p.redrawSelections(), v();
        }
        function g() {
          e(p.image).css(f),
            (p.scaleInfo = n.getScaleInfo(
              { width: t, height: r },
              { width: p.scaleInfo.realWidth, height: p.scaleInfo.realHeight }
            )),
            e.each(p.data, function (t, n) {
              e.each(n.areas(), function (e, t) {
                t.resize();
              });
            });
        }
        var o,
          a,
          f,
          l,
          c,
          h,
          p = this;
        s = s || i;
        if (p.scaleInfo.width === t && p.scaleInfo.height === r) return;
        (c = p.highlightId),
          t ||
            ((h = r / p.scaleInfo.realHeight),
            (t = Math.round(p.scaleInfo.realWidth * h))),
          r ||
            ((h = t / p.scaleInfo.realWidth),
            (r = Math.round(p.scaleInfo.realHeight * h))),
          (f = { width: String(t) + 'px', height: String(r) + 'px' }),
          e.mapster.hasCanvas || e(p.base_canvas).children().remove(),
          (l = e(p.wrapper).find('.mapster_el').add(p.wrapper)),
          i
            ? ((a = []),
              (p.currentAction = 'resizing'),
              l.each(function (t, r) {
                (o = n.defer()),
                  a.push(o),
                  e(r).animate(f, {
                    duration: i,
                    complete: o.resolve,
                    easing: 'linear',
                  });
              }),
              (o = n.defer()),
              a.push(o),
              n.when.all(a).then(m),
              g(),
              o.resolve())
            : (l.css(f), g(), m());
      }),
      (t.MapArea = n.subclass(t.MapArea, function () {
        this.base.init(), this.owner.scaleInfo.scale && this.resize();
      })),
      (r.coords = function (e, t) {
        var n,
          r = [],
          i = e || this.owner.scaleInfo.scalePct,
          s = t || 0;
        if (i === 1 && t === 0) return this.originalCoords;
        for (n = 0; n < this.length; n++)
          r.push(Math.round(this.originalCoords[n] * i) + s);
        return r;
      }),
      (r.resize = function () {
        this.area.coords = this.coords().join(',');
      }),
      (r.reset = function () {
        this.area.coords = this.coords(1).join(',');
      }),
      (t.impl.resize = function (e, n, r, i) {
        if (!e && !n) return !1;
        var s = new t.Method(
          this,
          function () {
            this.resize(e, n, r, i);
          },
          null,
          { name: 'resize', args: arguments }
        ).go();
        return s;
      });
  })(jQuery),
  (function (e) {
    function r(t, n, r) {
      var i;
      return (
        n
          ? ((i = typeof n == 'string' ? e(n) : e(n).clone()), i.append(t))
          : (i = e(t)),
        i
          .css(e.extend(r || {}, { display: 'block', position: 'absolute' }))
          .hide(),
        e('body').append(i),
        i.attr('data-opacity', i.css('opacity')).css('opacity', 0),
        i.show()
      );
    }
    function i(e, t) {
      var r = { left: t.left + 'px', top: t.top + 'px' },
        i = e.attr('data-opacity') || 0,
        s = e.css('z-index');
      if (parseInt(s, 10) === 0 || s === 'auto') r['z-index'] = 9999;
      e.css(r).addClass('mapster_tooltip'),
        t.fadeDuration && t.fadeDuration > 0
          ? n.fader(e[0], 0, i, t.fadeDuration)
          : n.setOpacity(e[0], i);
    }
    function s(t, n, r, i, s, o) {
      var u = r + '.mapster-tooltip';
      if (e.inArray(n, t) >= 0)
        return (
          i.unbind(u).bind(u, function (e) {
            if (!s || s.call(this, e))
              i.unbind('.mapster-tooltip'), o && o.call(this);
          }),
          { object: i, event: u }
        );
    }
    function o(e, t, r, s, o) {
      var u,
        a = {};
      return (
        (o = o || {}),
        t
          ? ((u = n.areaCorners(t, r, s, e.outerWidth(!0), e.outerHeight(!0))),
            (a.left = u[0]),
            (a.top = u[1]))
          : ((a.left = o.left), (a.top = o.top)),
        (a.left += o.offsetx || 0),
        (a.top += o.offsety || 0),
        (a.css = o.css),
        (a.fadeDuration = o.fadeDuration),
        i(e, a),
        e
      );
    }
    function u(e) {
      return e ? (typeof e == 'string' || e.jquery ? e : e.content) : null;
    }
    var t = e.mapster,
      n = t.utils;
    e.extend(t.defaults, {
      toolTipContainer:
        '<div style="border: 2px solid black; background: #EEEEEE; width:160px; padding:4px; margin: 4px; -moz-box-shadow: 3px 3px 5px #535353; -webkit-box-shadow: 3px 3px 5px #535353; box-shadow: 3px 3px 5px #535353; -moz-border-radius: 6px 6px 6px 6px; -webkit-border-radius: 6px; border-radius: 6px 6px 6px 6px; opacity: 0.8;"></dteniv>',
      showToolTip: !1,
      toolTipFade: !0,
      toolTipClose: ['area-mouseout', 'image-mouseout'],
      onShowToolTip: null,
      onHideToolTip: null,
    }),
      e.extend(t.area_defaults, { toolTip: null, toolTipClose: null }),
      (t.MapData.prototype.clearToolTip = function () {
        this.activeToolTip &&
          (this.activeToolTip.stop().remove(),
          (this.activeToolTip = null),
          (this.activeToolTipID = null),
          n.ifFunction(this.options.onHideToolTip, this));
      }),
      (t.AreaData.prototype.showToolTip = function (t, i) {
        var u,
          a,
          f,
          l,
          c,
          h = {},
          p = this,
          d = p.owner,
          v = p.effectiveOptions();
        (i = i ? e.extend({}, i) : {}),
          (t = t || v.toolTip),
          (a =
            i.closeEvents ||
            v.toolTipClose ||
            d.options.toolTipClose ||
            'tooltip-click'),
          (c =
            typeof i.template != 'undefined'
              ? i.template
              : d.options.toolTipContainer),
          (i.closeEvents = typeof a == 'string' ? (a = n.split(a)) : a),
          (i.fadeDuration =
            i.fadeDuration ||
            (d.options.toolTipFade
              ? d.options.fadeDuration || v.fadeDuration
              : 0)),
          (f = p.area
            ? p.area
            : e.map(p.areas(), function (e) {
                return e.area;
              }));
        if (d.activeToolTipID === p.areaId) return;
        return (
          d.clearToolTip(),
          (d.activeToolTip = u = r(t, c, i.css)),
          (d.activeToolTipID = p.areaId),
          (l = function () {
            d.clearToolTip();
          }),
          s(a, 'area-click', 'click', e(d.map), null, l),
          s(a, 'tooltip-click', 'click', u, null, l),
          s(
            a,
            'image-mouseout',
            'mouseout',
            e(d.image),
            function (e) {
              return (
                e.relatedTarget &&
                e.relatedTarget.nodeName !== 'AREA' &&
                e.relatedTarget !== p.area
              );
            },
            l
          ),
          o(u, f, d.image, i.container, c, i),
          n.ifFunction(d.options.onShowToolTip, p.area, {
            toolTip: u,
            options: h,
            areaOptions: v,
            key: p.key,
            selected: p.isSelected(),
          }),
          u
        );
      }),
      (t.impl.tooltip = function (n, i) {
        return new t.Method(
          this,
          function () {
            var a,
              f,
              l = this;
            if (!n) l.clearToolTip();
            else {
              f = e(n);
              if (l.activeToolTipID === f[0]) return;
              l.clearToolTip(),
                (l.activeToolTip = a =
                  r(u(i), i.template || l.options.toolTipContainer, i.css)),
                (l.activeToolTipID = f[0]),
                s(
                  ['tooltip-click'],
                  'tooltip-click',
                  'click',
                  a,
                  null,
                  function () {
                    l.clearToolTip();
                  }
                ),
                (l.activeToolTip = a = o(a, f, l.image, i.container, i));
            }
          },
          function () {
            e.isPlainObject(n) && !i && (i = n), this.showToolTip(u(i), i);
          },
          { name: 'tooltip', args: arguments, key: n }
        ).go();
      });
  })(jQuery);
